body {
  background-color: #1F1F1F;
  color: #ffffff;
  position: relative;
}

.App {
  overflow: hidden;
}

body, html,.App {
overflow: hidden;
}

a {
    color: #337ab7;
    text-decoration: none;
}
