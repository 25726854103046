body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Bebasneue/bebasneueregular.woff) format('woff');
  src: url(./fonts/Bebasneue/bebasneueregular.ttf) format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/Bebasneue/bebasneuelight.woff) format('woff');
  src: url(./fonts/Bebasneue/bebasneuelight.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Montserrat/Montserrat-Regular.woff) format('woff');
  src: url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/Montserrat/Montserrat-Medium.woff) format('woff');
  src: url(./fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/Montserrat/Montserrat-SemiBold.woff) format('woff');
  src: url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Montserrat/Montserrat-Bold.woff) format('woff');
  src: url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
